import React from "react"
import Layout from "../../../components/App/Layout"
import Navbar from "../../../components/App/Navbar"
import Footer from "../../../components/App/Footer"
import Seo from "../../../components/App/Seo"
import Gefahrgut from "../../../components/ProductDetails/netversys/Gefahrgut"

const VollautomatisierungPage = () => {
  return (
    <Layout>
      <Seo title={"Gefahrgut sicher versenden mit 100%iger Rechtskomformität"}
           description={"NETVERSYS befördert rechtskomform Gefahrgut. Die Auszeichnung von " +
           "Sendungen gehört genauso dazu, wie das Klassifizieren von Inhalten nach Gefahrengruppen."}
           image={"/images/og/netversys-main-og.png"}>
              <script type="application/ld+json">{`
                    {
                      "@context": "https://schema.org",
                      "@type": "BreadcrumbList",
                      "itemListElement": [{
                        "@type": "ListItem",
                        "position": 1,
                        "name": "Home",
                        "item": "https://logentis.de"
                      },{
                        "@type": "ListItem",
                        "position": 2,
                        "name": "Versandsoftware",
                        "item": "https://logentis.de/versandsoftware/"
                      },{
                        "@type": "ListItem",
                        "position": 3,
                        "name": "Gefahrgutversand",
                        "item": "https://logentis.de/versandsoftware/netversys/gefahrgutversand/"
                      }]
                    }
                    `}
              </script>
      </Seo>
      <Navbar />
      <Gefahrgut />
      <Footer />
    </Layout>
  );
}

export default VollautomatisierungPage
