import React from "react"
import gefahrgutversandHeader from "../../../assets/images/products/netversys/gefahrgut_header.svg"
import wmGefahrgut from "../../../assets/images/products/netversys/wm-gefahrgut.svg"
import Sidebar from "./Sidebar"
import { Col, Container, Row } from "react-bootstrap"
import WichtigeMerkmale from "../WichtigeMerkmale"
import LexikonBadgesRandomSection from "../../lexikon/LexikonBadgesRandomSection"
import FeatureSection from "../../App/FeatureSection"
import { StaticImage } from "gatsby-plugin-image"


const Gefahrgut = () => {

  return (
    <>
      <section className="services-details-area ptb-100">
        <Container className="mt-5">
          <Row>
            <Col lg={8} md={12}>
              <div className="services-details-image">
                <img src={gefahrgutversandHeader} alt="Gefahrgut" />
              </div>

              <div className="services-details-desc">
                <span className="sub-title">NETVERSYS</span>
                <h1 className={"pt-3"}>Gefahrgutversand</h1>
                <p>
                  Der Transport von Gefahrgütern erfordert besondere Aufmerksamkeit, da es gilt das Gefahrenpotential zu
                  minimieren. Zu diesem Zweck gibt es das rechtsgültige Europäische Übereinkommen über die
                  internationale Beförderung gefährlicher Güter auf der Straße (ADR). Im ADR sind Vorschriften
                  gesammelt, um den Transport von Gefahrgütern zu regeln. Die im ADR festgehaltenen Richtlinien sorgen
                  für transparente Bestimmungen was den Transport von Gefahrgütern angeht.
                </p>
                <p>
                  Für eine Versandsoftware ergeben sich daraus besondere Faktoren, die es zu beachten gilt, u.a.
                  folgende Fragestellungen:
                </p>
                <ul>
                  <li>Welche Verpackung soll verwendet werden?</li>
                  <li>Wie wird der Frachtführer informiert?</li>
                  <li>Wie wird das Gefahrgut angemeldet?</li>
                </ul>

                <WichtigeMerkmale items={[
                  "Passende Warenklassifizierung",
                  "Einfache Ausgabe der Beförderungspapiere",
                  "Optische Auszeichnung der Sendungen"
                ]} image={<img src={wmGefahrgut}
                               alt="Gefahrgut Merkmale"
                               className={"ml-lg-4 ml-0"} />} />

                <h3>Relevantes aus dem Lexikon</h3>

                <LexikonBadgesRandomSection />
              </div>
            </Col>

            <Col lg={4} md={12}>
              <Sidebar />
            </Col>
          </Row>
        </Container>
      </section>

      <FeatureSection backgroundColorClass={"bg-f1f8fb"}
                      imagePosition={"left"}
                      image={<StaticImage
                        src="../../../assets/images/products/netversys/screenshots/nv_dg_framed.png"
                        alt="Gefahtgut Screenshot"
                        placeholder="blurred"
                        width={600}
                      />}
                      header={"Gefahrgut versenden"}
                      textBlock1={<span>NETVERSYS als serverbasierte Versandsoftware ist in der Lage "headless" zu operieren. Das bedeutet, die
                nötigen Eingaben müssen nicht manuell übertragen werden, sondern auf einen intelligenten Datenfluss
                zwischen den Systemen zurückgegriffen wird.</span>}
                      textBlock2={<span>Mit NETVERSYS kann der Gefahrgutversand automatisiert in direkter Kommunikation
                        mit dem Vorsystem erfolgen, eine manuelle Eingabe ist selbstverständlich ebenfalls möglich.
                        NETVERSYS bietet alle dafür nötigen Eingabefelder in der GUI und ist so konzipiert,
                        dass Fehler beim Gefahrgutversand vermieden werden.</span>}
      />
    </>
  )
}

export default Gefahrgut
